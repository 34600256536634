.my-link:hover {
  background-color: rgba(4, 117, 107, 0.1);
  transform: scale(1.01);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  /* border-radius: 20px; */
}

.my-link {
  background-color: rgba(4, 117, 107, 0.1);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: rgba(79, 113, 189, 0.39);
  border-color: rgba(79, 113, 189, 0.39);
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #b6b4b4;
  border-radius: 5px;
}

/* Style for the scrollbar track when the handle is being hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Change the color on hover */
}

.dis .react-video-thumbnail-image img {
  height: 317px !important;
  width: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
}

.chatlist_height {
  height: 70vh;
  overflow-y: auto !important;
}

@media screen and (max-width:991px) {
  .chatlist_height {
    height: 70vh;
    margin-bottom: 15px;
    max-height: 70vh !important;
    overflow-y: auto;
  }
}