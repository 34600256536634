/* Set the color of the arrow buttons */
.slick-prev,
.slick-next {
  color: #007bff; /* Change to your desired color */
}

/* Set the color on hover (optional) */
.slick-prev:hover,
.slick-next:hover {
  color: #0056b3; /* Change to your desired color on hover */
}
/* EventDetails.css */
.comments-width {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.event-details, .bank-details, .ticket-details {
  margin-bottom: 18px;
}

h5{
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 5px;
  text-align: left;
}

th {
  background-color: #f1f1f1;
  font-weight: bold;
}

td {
  background-color: #fff;
}

tr:nth-child(even) td {
  background-color: #f9f9f9;
}

tr:hover td {
  background-color: #f1f1f1;
}
