body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.errorMessage-customer {
  width: 100% !important;
  margin-top: 0.25rem !important;
  font-size: 0.875em !important;
  color: #e55353 !important;
}

input[type="date"] {
  content: attr(placeholder);
  color: white;
}

input[type="date"]:focus,
input[type="date"]:valid {
  color: rgb(28, 26, 25);
}
