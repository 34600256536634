.inputbox {
    border: none;
    border-bottom: 1px solid gray;
    border-radius: 0;
    font-size: 18px;
    color: black;
    font-weight: 600;
}

.label {
    font-weight: 500;
    color: #5470af;
}