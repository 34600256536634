.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.imagePreview {
  width: 200px;
  height: 200px;
  border-radius: 8px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bread-item-text {
  text-decoration-line: none;
  color: black;
}

.slick-dots {
  color: #3c4b64 !important;
  bottom: 31px !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick" !important;
  font-size: 20px !important;
  line-height: 1 !important;
  opacity: 0.75 !important;
  color: white !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.slick-prev {
  left: 5px !important;
  z-index: 1 !important;
}

.slick-next {
  right: 5px !important;
}

.slick-dots li {
  margin: 0 4px !important;
}
.slick-dots li button:before {
  font-size: 18px !important;
  color: #3c4b64 !important;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75 !important;
  color: #3c4b64 !important;
}
.sidebar-nav .nav-link.active {
  color: #4f71bd;
  background: white;
  border: 2px solid #4f71bd;
  border-radius: 5px;
}
.sidebar-nav .nav-link.active .nav-icon {
  color: rgb(79 113 189);
}
