.carousel-width {
    width: 60%;
}

.comments-width {
    width: 40%;
}

.carousel_height {
    max-height: 500px;
}
.carousel_div_display{
    display: flex;
    flex-direction: row;
}

@media screen and (max-width:426px) {
    .carousel_div_display{
        display: flex;
        flex-direction: column;
    }
    .carousel-width {
        width: 100%;
    }

    .comments-width {
        width: 100%;
    }

    .carousel_height {
        max-height: 300px;
    }
}