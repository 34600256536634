.dashbord-card-line .progress {
    display: none;
}

.title-Dashboard {
    margin-top: -21px !important;
}

.post {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.post-icons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.icon {
    display: flex;
    align-items: center;
}

.post-body {
    width: 100%;
}

.post-body video,
.post-body img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 5px;
}

.profile-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

/* Styles for the user items container */
ul {
    list-style: none;
    padding: 0;
}

/* Styles for each user item */
.user-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
}

/* Styles for user details */
.user-details {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

/* Styles for the user name */
.user-name {
    font-weight: bold;
    margin: 0;
}

/* Styles for the follow button */
.btn-primary {
    margin-top: 5px;
}

.nav-link.active {
    color: black;
}

.custom-button {
    margin-left: 5px;
    font-size: 14px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    cursor: pointer;
}

.custom-button.active {
    color: black;
}

.user-item:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

.user-item {}

.showDropDown {
    z-index: 1;
    position: absolute;
    margin-top: -23px;
    margin-left: 260px;
    padding: 10px;
    border-radius: 20px;
    width: 145px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: white;
    background-color: #4f71bd;
    font-size: 16px;
}

.nav-pills .nav-link,
.nav-pills .show>.nav-link {
    color: black;
}